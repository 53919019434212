.voucherInputPin {
	width: 50% !important;
	height: 45px;
	border-top: none;
	border-left: none;
	border-right: none;
	border-width: 1px;
	border-color: #bdbdbd;
	margin-left: 8px;
	margin-right: 8px;
	font-size: 20px;
}

.voucherInputPin:focus {
	outline: none !important;
	border-width: 2px;
	border-color: #42a5f5;
}

.voucherInputPin:disabled {
	background: transparent;
}