.Machine-trigger-button {
	width: 200px;
	margin: 15px 0;
}

.Machine-button-wrapper {
	width: 200px;
	margin: auto;
}

.Machine-lock-button-wrapper {
	margin-right: 20px;
}

.btn-tenant {
	width: 150px;
	margin-top: 15px;
}

.danger-background {
	background: repeating-linear-gradient(-45deg, rgba(252, 50, 67, 0.41), rgba(252, 50, 67, 0.37) 10px, #fff 10px, #fff 20px);
}

.light-background {
	background-color: lightblue;
}
