.section-container {
	width: 100%;
	padding-right: 32px;
}

.App-content {
	margin-top: 32px;
}

.expedited-text {
	text-align: left !important;
	padding-left: 30px;
}

.grid-item {
	cursor: default;
	text-align: center;
	font-weight: bold;
	padding: 0 !important;
	margin: 12px 24px;
	border-radius: 27px;
	user-select: none;
}

.grid-item-button-wrapper {
	height: 100%;
}

.grid-item-button {
	height: 130px;
	width: 50%;
	padding: auto 0;
	background: transparent;
	border-width: 0;
}

.grid-item-button.grid-item-button-single {
	width: 100% !important;
}

.grid-item-button.grid-item-button-clean {
	border-width: 5px;
	border-radius: 27px;
	border-color: #a1edb0;
}

.grid-item-button.grid-item-button-stay {
	border-width: 5px;
	border-radius: 27px;
	border-color: #198bfc;
}

.grid-item-button.grid-item-button-leave {
	border-width: 5px;
	border-radius: 27px;
	border-color: #fc3243;
}

.grid-item-button.grid-item-button-expedited {
	border-width: 5px;
	border-radius: 27px;
	border-color: #fc8dfc;
}

.grid-item-button.grid-item-button-clean-unchecked {
	border-width: 5px;
	border-radius: 27px;
	border-color: #fbfc88;
}

.grid-item-button.grid-item-button-disabled {
	border-width: 5px;
	border-radius: 27px;
	color: #555;
	background: #bbb repeating-linear-gradient(-55deg, #ddd, #ddd 4px, #bbb 4px, #bbb 8px);
	opacity: 0.78;
}

.grid-item-room-info {
	margin-bottom: 23px;
}

.grid-item-room-state {
	padding-top: 50px;
	font-size: 20px;
}

.grid-item-room-label {
	font-size: 30px;
	left: 20px;
	top: 10px;
	position: absolute;
}

.grid-room-wrapper {
	padding: 0 1px 0 16px;
}

.metal {
	text-align: center;
	color: hsla(0, 0%, 20%, 1);
	text-shadow: hsla(0, 0%, 40%, 0.5) 0 -1px 0, hsla(0, 0%, 100%, 0.6) 0 2px 1px;

	background-color: hsl(0, 0%, 90%);
	box-shadow: inset hsla(0, 0%, 15%, 1) 0 0px 0px 4px, /* border */ inset hsla(0, 0%, 15%, 0.8) 0 -1px 5px 4px, /* soft SD */ inset hsla(0, 0%, 0%, 0.25) 0 -1px 0px 7px, /* bottom SD */ inset hsla(0, 0%, 100%, 0.7) 0 2px 1px 7px, /* top HL */ hsla(0, 0%, 0%, 0.15) 0 -5px 6px 4px,
		/* outer SD */ hsla(0, 0%, 100%, 0.5) 0 5px 6px 4px; /* outer HL */

	/*transition: color .2s;*/
}

.metal.linear {
	background-image: linear-gradient(180deg, hsl(0, 0%, 88%) 0%, hsl(0, 0%, 100%) 47%, hsl(0, 0%, 88%) 53%, hsl(0, 0%, 80%) 100%);
}

.metal.fully-linear {
	background-image: -webkit-repeating-linear-gradient(left, hsla(0, 0%, 100%, 0) 0%, hsla(0, 0%, 100%, 0) 3%, hsla(0, 0%, 100%, 0.1) 6%), -webkit-repeating-linear-gradient(left, hsla(0, 0%, 0%, 0) 0%, hsla(0, 0%, 0%, 0) 2%, hsla(0, 0%, 0%, 0.03) 4%),
		-webkit-repeating-linear-gradient(left, hsla(0, 0%, 100%, 0) 0%, hsla(0, 0%, 100%, 0) 1%, hsla(0, 0%, 100%, 0.15) 2%), linear-gradient(180deg, hsl(0, 0%, 88%) 0%, hsl(0, 0%, 100%) 47%, hsl(0, 0%, 88%) 53%, hsl(0, 0%, 80%) 100%);
}
