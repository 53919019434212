.ql-editor {
	padding: 0!important;
}

.ql-editor p {
	font-size: 1rem!important;
}

blockquote {
	border-left: 4px solid #ccc;
	margin-bottom: 5px;
	margin-top: 5px;
	padding-left: 16px;
}

.ql-font-serif {
	font-family: Georgia, Times New Roman, serif;
}

.ql-size-small {
	font-size: 0.75em;
}

.ql-size-large {
	font-size: 1.5em;
}

.ql-size-huge {
	font-size: 2.5em;
}

.ql-bubble .ql-tooltip {
	left: 0% !important;
}