.App {
	text-align: center;
}

button:focus {
	outline: none;
}

.App-content {
	top: 35px;
	position: absolute;
	width: 100%;
	padding-bottom: 15px;
}

.Ellipsys {
	white-space: nowrap !important;
	overflow: hidden !important;
	text-overflow: ellipsis !important;
}